import {CustomQuery} from "./types";

export const getFeaturedProducts: CustomQuery = {
    queryKey: "productsWithImages",
    type: "LIST",
   query: /* GraphQL */ `
       query ProductsWithImages(
           $hasImage: ID!
           $latestImageAddedAt: ModelStringKeyConditionInput
           $sortDirection: ModelSortDirection
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           productsWithImages(
               hasImage: $hasImage
               latestImageAddedAt: $latestImageAddedAt
               sortDirection: $sortDirection
               filter: $filter
               limit: $limit
               nextToken: $nextToken
           ) {
               items {
                   id
                   distributorId
                   hasImage
                   hasIso2022
                   productKey
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   sku
                   submittedMakerSeller
                   description
                   weightGrams
                   variantOptions
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   latestImageAddedAt
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           distributorId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key
                           altText
                           title
                           width
                           height
                           position
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}

export const getProductIndex: CustomQuery = {
    queryKey: "listIso2022s",
    type: "LIST",
   query: /* GraphQL */ `
       query ListIso2022s(
           $filter: ModelIso2022FilterInput
           $limit: Int
           $nextToken: String
       ) {
           listIso2022s(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   title
                   description
                   plainLanguageTitle
                   plainLanguageDescription
                   class
                   subclass
                   division
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
                   products {
                       items {
                           id
                           product {
                               id
                               distributorId
                               name
                               listPrice
                               manufacturer
                               gtin
                               asin
                               submittedMakerSeller
                               description
                               keywords
                               details
                               technicalSpecifications
                               videoUrl
                               internalNotes
                               images {
                                   items {
                                       id
                                       productId
                                       articleId
                                       articleComponentId
                                       discussionId
                                       reviewId
                                       profileUserId
                                       bannerUserId
                                       url
                                       prefix
                                       key

                                       altText
                                       title
                                       width
                                       height
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   nextToken
                                   startedAt
                                   __typename
                               }
                               distributor {
                                   id
                                   name
                                   description
                                   abbreviation
                                   phone
                                   internationalPhone
                                   fax
                                   tty
                                   contactName
                                   contactTitle
                                   contactPhone
                                   contactEmail
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                       }
                       nextToken
                   }
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}

export const getProductEditView: CustomQuery = {
    queryKey: "getProduct",
    type: "GET",
   query: /* GraphQL */ `
       query GetProduct($id: ID!) {
           getProduct(id: $id) {
               id
               distributorId
               name
               discontinued
               listPrice
               manufacturer
               gtin
               asin
               submittedMakerSeller
               description
               keywords
               details
               technicalSpecifications
               videoUrl
               internalNotes
               iso2022s
               {
                   items
                   {
                       id
                       productID
                       iso2022ID
                       _deleted
                       _version
                       iso2022
                       {
                           id
                           class
                           title
                           description
                           plainLanguageTitle
                           plainLanguageDescription
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                   }
               }
               prices
               {
                   items
                   {
                       id
                       productId
                       distributorId
                       price
                       url
                       linkText
                       pricingNotes
                       distributor
                       {
                           id
                           name
                           description
                           abbreviation
                           phone
                           internationalPhone
                           fax
                           tty
                           contactName
                           contactTitle
                           contactPhone
                           contactEmail
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
               }
               images
               {
                   items {
                       id
                       productId
                       articleComponentId
                       discussionId
                       reviewId
                       url
                       prefix
                       key

                       position
                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
                   nextToken
                   startedAt
               }
               distributor
               {
                   id
                   name
                   description
                   abbreviation
                   phone
                   internationalPhone
                   fax
                   tty
                   contactName
                   contactTitle
                   contactPhone
                   contactEmail
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
           }
       }
   `
}

export const getProductView: CustomQuery = {
    queryKey: "getProduct",
    type: "GET",
   query: /* GraphQL */ `
       query GetProduct($id: ID!) {
           getProduct(id: $id) {
               id
               distributorId
               name
               discontinued
               listPrice
               manufacturer
               gtin
               asin
               submittedMakerSeller
               description
               keywords
               details
               technicalSpecifications
               variantOptions
               videoUrl
               internalNotes
               iso2022s
               {
                   items
                   {
                       _deleted
                       _version
                       iso2022
                       {
                           id
                           class
                           title
                           description
                           plainLanguageTitle
                           plainLanguageDescription
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                   }
               }
               prices
               {
                   items
                   {
                       id
                       productId
                       distributorId
                       price
                       pricingNotes
                       linkText
                       url
                       distributor
                       {
                           id
                           name
                           description
                           abbreviation
                           phone
                           internationalPhone
                           fax
                           tty
                           contactName
                           contactTitle
                           contactPhone
                           contactEmail
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
               }
               vendorLinks
               {
                   nextToken
                   startedAt
               }
               images
               {
                   items {
                       id
                       productId
                       articleComponentId
                       discussionId
                       reviewId
                       url
                       prefix
                       key

                       altText
                       title
                       width
                       height
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
                   nextToken
                   startedAt
               }
               videos
               {
                   nextToken
                   startedAt
               }
               questions
               {
                   items {
                       id
                       userId
                       productId
                       parentQuestionId
                       body
                       answers {
                           items {
                               id
                               userId
                               productId
                               body
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                       votes {
                           items {
                               id
                               userId
                               commentId
                               reviewId
                               questionId
                               vote
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
                   nextToken
                   startedAt
               }
               ratings
               {
                   items
                   {
                       id
                       userId
                       productId
                       reviewId
                       rating
                       user
                       {
                           id
                           distributorId
                           firstName
                           displayName
                           lastName
                           email
                           profile
                           roles
                           enabled
                           createdAt
                           updatedAt
                           profileImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               profileUserId
                               bannerUserId
                               url
                               prefix
                               key

                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       review
                       {
                           id
                           userId
                           productId
                           parentReviewId
                           body
                           responses {
                               items {
                                   id
                                   userId
                                   productId
                                   parentReviewId
                                   body

                                   user {
                                       id
                                       distributorId
                                       firstName
                                       lastName
                                       displayName
                                       email
                                       profile
                                       roles
                                       enabled
                                       createdAt
                                       updatedAt
                                       profileImage {
                                           id
                                           productId
                                           articleId
                                           articleComponentId
                                           discussionId
                                           reviewId
                                           profileUserId
                                           bannerUserId
                                           url
                                           prefix
                                           key

                                           altText
                                           title
                                           width
                                           height
                                           createdAt
                                           updatedAt
                                           _version
                                           _deleted
                                           _lastChangedAt
                                           __typename
                                       }
                                       _version
                                       _deleted
                                       _lastChangedAt
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                               }
                           }
                           votes {
                               items {
                                   id
                                   userId
                                   commentId
                                   reviewId
                                   questionId
                                   vote
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                               }
                           }
                           images {
                               items {
                                   id
                                   productId
                                   articleComponentId
                                   discussionId
                                   reviewId
                                   url
                                   prefix
                                   key

                                   altText
                                   title
                                   width
                                   height
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                               }
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                   }
               }
               distributor
               {
                   id
                   name
                   description
                   abbreviation
                   phone
                   internationalPhone
                   fax
                   tty
                   contactName
                   contactTitle
                   contactPhone
                   contactEmail
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
               }
               variants {
                   items {
                       id
                       variantKey
                       listPrice
                       gtin
                       asin
                       sku
                       description
                       weightGrams
                   }
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
           }
       }
   `
}

export const getProductsWithoutImages: CustomQuery = {
    queryKey: "productsWithImages",
    type: "LIST",
   query: /* GraphQL */ `
       query ProductsWithImages(
           $hasImage: ID!
           $latestImageAddedAt: ModelStringKeyConditionInput
           $sortDirection: ModelSortDirection
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           productsWithImages(
               hasImage: $hasImage
               latestImageAddedAt: $latestImageAddedAt
               sortDirection: $sortDirection
               filter: $filter
               limit: $limit
               nextToken: $nextToken
           ) {
               items {
                   id
                   distributorId
                   hasImage
                   hasIso2022
                   productKey
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   sku
                   submittedMakerSeller
                   description
                   weightGrams
                   variantOptions
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   latestImageAddedAt
                   iso2022s {
                       items
                       {
                           id
                           productID
                           iso2022ID
                           _deleted
                           _version
                           iso2022
                           {
                               id
                               class
                               title
                               description
                               plainLanguageTitle
                               plainLanguageDescription
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                   }
                   prices {
                       items {
                           id
                           productId
                           distributorId
                           price
                           pricingNotes
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key

                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   _version
                   _deleted
               }
               nextToken
           }
       }
   `
}

export const getProductsWithoutIsos: CustomQuery = {
    queryKey: "productsWithIso2022s",
    type: "LIST",
   query: /* GraphQL */ `
       query ProductsWithIso2022s(
           $hasIso2022: ID!
           $createdAt: ModelStringKeyConditionInput
           $sortDirection: ModelSortDirection
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           productsWithIso2022s(
               hasIso2022: $hasIso2022
               createdAt: $createdAt
               sortDirection: $sortDirection
               filter: $filter
               limit: $limit
               nextToken: $nextToken
           ) {
               items {
                   id
                   distributorId
                   hasImage
                   hasIso2022
                   productKey
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   sku
                   submittedMakerSeller
                   description
                   weightGrams
                   variantOptions
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   latestImageAddedAt
                   iso2022s {
                       items
                       {
                           id
                           productID
                           iso2022ID
                           _deleted
                           _version
                           iso2022
                           {
                               id
                               class
                               title
                               description
                               plainLanguageTitle
                               plainLanguageDescription
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                   }
                   prices {
                       items {
                           id
                           productId
                           distributorId
                           price
                           pricingNotes
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key

                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   _version
                   _deleted
               }
               nextToken
           }
       }
   `
}

export const getProductsWithoutDistributors: CustomQuery = {
    queryKey: "productsWithDistributors",
    type: "LIST",
   query: /* GraphQL */ `
       query ProductsWithDistributors(
           $hasDistributor: ID!
           $createdAt: ModelStringKeyConditionInput
           $sortDirection: ModelSortDirection
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           productsWithDistributors(
               hasDistributor: $hasDistributor
               createdAt: $createdAt
               sortDirection: $sortDirection
               filter: $filter
               limit: $limit
               nextToken: $nextToken
           ) {
               items {
                   id
                   distributorId
                   hasImage
                   hasIso2022
                   hasDistributor
                   productKey
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   sku
                   submittedMakerSeller
                   description
                   weightGrams
                   variantOptions
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   latestImageAddedAt
                   iso2022s {
                       items
                       {
                           id
                           productID
                           iso2022ID
                           _deleted
                           _version
                           iso2022
                           {
                               id
                               class
                               title
                               description
                               plainLanguageTitle
                               plainLanguageDescription
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                   }
                   prices {
                       items {
                           id
                           productId
                           distributorId
                           price
                           pricingNotes
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key

                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   _version
                   _deleted
               }
               nextToken
           }
       }
   `
}

export const getProductSearch: CustomQuery = {
    queryKey: "searchProducts",
    type: "LIST",
   query: /* GraphQL */ `
       query SearchProducts(
           $filter: SearchableProductFilterInput
           $sort: [SearchableProductSortInput]
           $limit: Int
           $nextToken: String
           $from: Int
           $aggregates: [SearchableProductAggregationInput]
       ) {
           searchProducts(
               filter: $filter
               sort: $sort
               limit: $limit
               nextToken: $nextToken
               from: $from
               aggregates: $aggregates
           ) {
               items {
                   id
                   distributorId
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   submittedMakerSeller
                   description
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   iso2022s {
                       items {
                           id
                           productID
                           iso2022ID
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   prices {
                       items {
                           id
                           productId
                           distributorId
                           price
                           pricingNotes
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key

                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   distributor {
                       id
                       name
                       description
                       abbreviation
                       phone
                       internationalPhone
                       fax
                       tty
                       contactName
                       contactTitle
                       contactPhone
                       contactEmail
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   _version
                   _deleted
                   __typename
               }
               nextToken
               total
               aggregateItems {
                   name
                   result {
                       ... on SearchableAggregateScalarResult {
                           value
                       }
                       ... on SearchableAggregateBucketResult {
                           buckets {
                               key
                               doc_count
                               __typename
                           }
                       }
                   }
                   __typename
               }
               __typename
           }
       }
   `
}

export const getProductSearchByIso: CustomQuery = {
    queryKey: "getIso",
    type: "GET",
   query: /* GraphQL */ `
       query GetIso($id: ID!) {
           getIso(id: $id) {
               id
               title
               description
               plainLanguageTitle
               plainLanguageDescription
               class
               subclass
               division
               products {
                   items {
                       product {
                           id
                           distributorId
                           name
                           listPrice
                           manufacturer
                           gtin
                           asin
                           submittedMakerSeller
                           description
                           keywords
                           details
                           technicalSpecifications
                           videoUrl
                           internalNotes
                           iso2022s {
                               items {
                                   id
                                   productID
                                   iso2022ID
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               nextToken
                               startedAt
                               __typename
                           }
                           prices {
                               items {
                                   id
                                   productId
                                   distributorId
                                   price
                                   pricingNotes
                                   distributor {
                                       id
                                       name
                                       description
                                       abbreviation
                                       phone
                                       internationalPhone
                                       fax
                                       tty
                                       contactName
                                       contactTitle
                                       contactPhone
                                       contactEmail
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               nextToken
                               startedAt
                               __typename
                           }
                           images {
                               items {
                                   id
                                   productId
                                   articleId
                                   articleComponentId
                                   discussionId
                                   reviewId
                                   profileUserId
                                   bannerUserId
                                   url
                                   prefix
                                   key

                                   altText
                                   title
                                   width
                                   height
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               nextToken
                               startedAt
                               __typename
                           }
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
                   nextToken
                   startedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }`
}

export const getProductSearchByIsoClass: CustomQuery = {
    queryKey: "listIso2022s",
    type: "LIST",
   query: /* GraphQL */ `
       query ListIso2022s(
           $filter: ModelIso2022FilterInput
           $limit: Int
           $nextToken: String)
       {
           listIso2022s(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   title
                   description
                   class
                   subclass
                   division
                   products {
                       items {
                           product {
                               id
                               distributorId
                               name
                               listPrice
                               manufacturer
                               gtin
                               asin
                               submittedMakerSeller
                               description
                               keywords
                               details
                               technicalSpecifications
                               videoUrl
                               internalNotes
                               iso2022s {
                                   items {
                                       id
                                       productID
                                       iso2022ID
                                       createdAt
                                       updatedAt
                                       _version
                                       _lastChangedAt
                                       _deleted
                                   }
                                   nextToken
                                   startedAt
                               }
                               prices {
                                   items {
                                       id
                                       productId
                                       distributorId
                                       price
                                       pricingNotes
                                       distributor {
                                           id
                                           name
                                           description
                                           abbreviation
                                           phone
                                           internationalPhone
                                           fax
                                           tty
                                           contactName
                                           contactTitle
                                           contactPhone
                                           contactEmail
                                           createdAt
                                           updatedAt
                                           _version
                                           _lastChangedAt
                                           _deleted
                                       }
                                       createdAt
                                       _version
                                       _lastChangedAt
                                       _deleted
                                       updatedAt
                                   }
                                   nextToken
                                   startedAt
                               }
                               images {
                                   items {
                                       id
                                       productId
                                       articleId
                                       articleComponentId
                                       discussionId
                                       profileUserId
                                       reviewId
                                       bannerUserId
                                       url
                                       prefix
                                       key

                                       altText
                                       title
                                       width
                                       updatedAt
                                       height
                                       createdAt
                                       _version
                                       _lastChangedAt
                                       _deleted
                                   }
                                   nextToken
                                   startedAt
                               }
                               distributor {
                                   id
                                   name
                                   description
                                   abbreviation
                                   internationalPhone
                                   phone
                                   tty
                                   fax
                                   contactPhone
                                   contactName
                                   contactEmail
                                   contactTitle
                                   createdAt
                                   _deleted
                                   _lastChangedAt
                                   _version
                                   updatedAt
                               }
                               createdAt
                               _version
                               _lastChangedAt
                               _deleted
                           }
                           updatedAt
                           createdAt
                           _version
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   updatedAt
               }
               nextToken
           }
       }
   `
}

export const getProductsByDistributor: CustomQuery = {
    queryKey: "productsByDistributor",
    type: "LIST",
   query: /* GraphQL */ `
       query ProductsByDistributor(
           $distributorId: ID!
           $sortDirection: ModelSortDirection
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           productsByDistributor(
               distributorId: $distributorId
               sortDirection: $sortDirection
               filter: $filter
               limit: $limit
               nextToken: $nextToken
           ) {
               items {
                   id
                   distributorId
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   submittedMakerSeller
                   description
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   prices {
                       items {
                           id
                           productId
                           distributorId
                           price
                           linkText
                           url
                           pricingNotes
                           distributor {
                               id
                               name
                               description
                               abbreviation
                               phone
                               internationalPhone
                               fax
                               tty
                               contactName
                               contactTitle
                               contactPhone
                               contactEmail
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                   }
                   iso2022s {
                       items
                       {
                           id
                           productID
                           iso2022ID
                           _deleted
                           _version
                           iso2022
                           {
                               id
                               class
                               title
                               description
                               plainLanguageTitle
                               plainLanguageDescription
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                           }
                       }
                   }
                   images {
                       items {
                           id
                           productId
                           articleComponentId
                           discussionId
                           reviewId
                           url
                           prefix
                           key

                           position
                           altText
                           title
                           width
                           height
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                       }
                       nextToken
                       startedAt
                   }
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}

export const listProductIsos: CustomQuery = {
    queryKey: "listProductIsos",
    type: "LIST",
   query:  /* GraphQL */ `
       query ListProductIsos(
           $filter: ModelProductIsoFilterInput
           $limit: Int
           $nextToken: String
       ) {
           listProductIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   productID
                   iso2022ID
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}

export const listTempProductIsos: CustomQuery = {
    queryKey: "listTempProductIsos",
    type: "LIST",
   query:  /* GraphQL */ `
       query ListTempProductIsos(
           $filter: ModelTempProductIsoFilterInput
           $limit: Int
           $nextToken: String
       ) {
           listTempProductIsos(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   productID
                   iso2022ID
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}

export const listProductsForIndices: CustomQuery = {
    queryKey: "listProducts",
    type: "LIST",
   query: /* GraphQL */ `
       query ListProducts(
           $filter: ModelProductFilterInput
           $limit: Int
           $nextToken: String
       ) {
           listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
               items {
                   id
                   distributorId
                   hasImage
                   hasDistributor
                   hasIso2022
                   productKey
                   name
                   listPrice
                   manufacturer
                   gtin
                   asin
                   sku
                   submittedMakerSeller
                   description
                   weightGrams
                   variantOptions
                   keywords
                   details
                   technicalSpecifications
                   videoUrl
                   internalNotes
                   latestImageAddedAt
                   images {
                       items {
                           id
                           productId
                           articleId
                           articleComponentId
                           discussionId
                           reviewId
                           distributorId
                           profileUserId
                           bannerUserId
                           url
                           prefix
                           key
                           altText
                           title
                           width
                           height
                           position
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   iso2022s {
                       items {
                           id
                           productID
                           iso2022ID
                           createdAt
                           updatedAt
                           _version
                           _lastChangedAt
                           _deleted
                       }
                       nextToken
                       startedAt
                   }
                   prices {
                       items {
                           id
                           productId
                           variantId
                           distributorId
                           price
                           linkText
                           url
                           pricingNotes
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                   }
                   variants {
                       items {
                           id
                           productId
                           distributorId
                           variantKey
                           listPrice
                           gtin
                           asin
                           sku
                           status
                           description
                           weightGrams
                           prices {
                               items {
                                   id
                                   productId
                                   variantId
                                   distributorId
                                   price
                                   linkText
                                   url
                                   pricingNotes
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       nextToken
                       startedAt
                       __typename
                   }
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               nextToken
               startedAt
               __typename
           }
       }
   `
}
